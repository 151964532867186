import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Phone, Mail, MapPin, Sparkles } from 'lucide-react';
import emailjs from "emailjs-com";
import { Toaster, toast } from "react-hot-toast";

const Contact = ({ setCurrentPage }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [hoveredField, setHoveredField] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const serviceID = "service_hxde4xk"; 
        const templateID = "template_jsh84nk"; 
        const publicKey = "eBmZv_Dnd_C91oAw1"; 

        const emailParams = {
            from_name: formData.name,
            from_email: formData.email,
            message: formData.message,
            to_email: "support@jaycolinks.com", 
        };

        try {
            await emailjs.send(serviceID, templateID, emailParams, publicKey);
            toast.success("Message sent successfully! 🎉", {
                duration: 4000, 
                position: "top-center",
            });
            setFormData({ name: "", email: "", message: "" });
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error("Failed to send message. Please try again.", {
                duration: 5000, 
                position: "top-center",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: 0.5 }
        }
    };

    const floatingAnimation = {
        y: [-5, 5],
        transition: {
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
        }
    };

    return (
        <div className='min-h-screen w-full relative flex flex-col items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white px-4 sm:px-6 lg:px-8 py-8 md:py-12 overflow-hidden' style={{
            backgroundImage: "url('/assets/jb2.jpg')",
            backgroundBlendMode: "overlay",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}>
            <Toaster position="top-center" reverseOrder={false} />
            
            <motion.div 
                className="w-full max-w-6xl mx-auto relative z-10 space-y-6 md:space-y-8"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <motion.div
                    className="relative"
                    animate={floatingAnimation}
                >
                    <motion.h1 
                        className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-4 md:mb-8 bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300"
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: "spring", stiffness: 300 }}
                    >
                        Get in Touch
                        <motion.span
                            className="absolute -top-2 -right-2"
                            animate={{ rotate: [0, 360] }}
                            transition={{ duration: 4, repeat: Infinity, ease: "linear" }}
                        >
                            <Sparkles className="w-6 h-6 text-yellow-400" />
                        </motion.span>
                    </motion.h1>
                </motion.div>

                <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
                <motion.div 
                        variants={itemVariants}
                        whileHover={{ scale: 1.02 }}
                        transition={{ type: "spring", stiffness: 300 }}
                        className="space-y-6"
                    >
                        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 lg:p-8 hover:bg-white/15 transition-colors duration-300">
                            <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                            <div className="space-y-6">
                                {[
                                    { 
                                        icon: Phone, 
                                        text: "Phone", 
                                        content: [
                                            "+234 (0) 2018885695 - (Landline)", 
                                            "+234 (0) 2018885696 - (Landline)",
                                            "+1(628)-281-2829 - (USA)",
                                            "0807 494 7346 - (Mobile)"
                                        ] 
                                    },
                                    { icon: Mail, text: "Email", content: ["support@jaycolinks.com"] },
                                    { 
                                        icon: MapPin, 
                                        text: "Address", 
                                        content: [ 
                                            "22 Dr Omon Ebhomenye Street, off Admiralty Way, Lekki Phase One, Lagos.",
                                            "16192 Coastal Highway, Lewes, Delaware, 19958."
                                        ],
                                        labels: ["Nigerian Address", "United States Address"],
                                        displayMode: "spaced" // Custom property to indicate we want spacing
                                    }
                                ].map((item, index) => (
                                    <motion.div
                                        key={index}
                                        className="flex items-start space-x-3"
                                        whileHover={{ x: 10 }}
                                        transition={{ type: "spring", stiffness: 300 }}
                                    >
                                        <item.icon className="w-5 h-5 text-gray-300 mt-1" />
                                        <div className="w-full">
                                            <p className="text-gray-300">{item.text}:</p>
                                            {item.text === "Address" && item.displayMode === "spaced" ? (
                                                // Render addresses with more spacing in between
                                                <div className="space-y-4">
                                                    {item.content.map((line, i) => (
                                                        <div key={i} className="pt-1">
                                                            <p className="text-white font-medium">{item.labels[i]}:</p>
                                                            <p className="text-white">{line}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                // Regular rendering for other items
                                                item.content.map((line, i) => (
                                                    <p key={i} className="text-white">
                                                        {item.text === "Phone" 
                                                            ? `Phone ${i + 1}: ${line}`
                                                            : line}
                                                    </p>
                                                ))
                                            )}
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.div>

                    <motion.div 
                        variants={itemVariants}
                        whileHover={{ scale: 1.02 }}
                        transition={{ type: "spring", stiffness: 300 }}
                        className="bg-white/10 backdrop-blur-sm rounded-lg p-6 lg:p-8 hover:bg-white/15 transition-colors duration-300"
                    >
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {[
                                { id: "name", label: "Name", type: "text" },
                                { id: "email", label: "Email", type: "email" }
                            ].map((field) => (
                                <motion.div
                                    key={field.id}
                                    whileHover={{ scale: 1.02 }}
                                    whileFocus={{ scale: 1.02 }}
                                >
                                    <label htmlFor={field.id} className="block text-sm font-medium text-gray-200 mb-2">
                                        {field.label}
                                    </label>
                                    <motion.input
                                        type={field.type}
                                        id={field.id}
                                        name={field.id}
                                        value={formData[field.id]}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-2 bg-white/5 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/50 text-white"
                                        placeholder={`Your ${field.id}`}
                                        onFocus={() => setHoveredField(field.id)}
                                        onBlur={() => setHoveredField(null)}
                                    />
                                </motion.div>
                            ))}

                            <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileFocus={{ scale: 1.02 }}
                            >
                                <label htmlFor="message" className="block text-sm font-medium text-gray-200 mb-2">
                                    Message
                                </label>
                                <motion.textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    rows={4}
                                    className="w-full px-4 py-2 bg-white/5 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/50 text-white resize-none"
                                    placeholder="Your message"
                                    onFocus={() => setHoveredField('message')}
                                    onBlur={() => setHoveredField(null)}
                                />
                            </motion.div>

                            <motion.button
                                type="submit"
                                disabled={isSubmitting}
                                className="w-full px-6 py-3 bg-white text-gray-900 rounded-lg font-medium transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                                whileHover={{ scale: 1.05, backgroundColor: "#f3f4f6" }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {isSubmitting ? 'Sending...' : 'Send Message'}
                            </motion.button>
                        </form>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
};

export default Contact;