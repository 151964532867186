import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Star, ChevronRight } from 'lucide-react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const IndustryPortfolio = ({ setCurrentPage }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [scrollY, setScrollY] = useState(0);

  // Track scroll position for parallax effects
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Industries derived from existing products plus compliance
  const industries = [
    {
      title: "Wellness & Lifestyle",
      description: "Pioneering premium lifestyle solutions through premium dating services, innovative wellness services, self-care essentials, and personalized experiences that enhance quality of life.",
      icon: "✨",
      products: ["Rendezvous Luxury Care Services"],
      // stats: { projects: 5, clients: "500+", satisfaction: "97%" }
    },
    {
      title: "Financial Technology",
      description: "Transforming business operations with cutting-edge financial technologies, streamlined payroll solutions, and intelligent HR management systems.",
      icon: "💹",
      products: ["Payrendr"],
      // stats: { projects: 8, clients: "1,200+", satisfaction: "95%" }
    },
    {
      title: "Marketplace Platforms",
      description: "Creating dynamic ecosystems that connect service providers with consumers, enabling seamless transactions and fostering community growth across specialized sectors.",
      icon: "🔄",
      products: ["Ndonu"],
      // stats: { projects: 6, clients: "3,500+", satisfaction: "92%" }
    },
    {
      title: "Logistics & Transportation",
      description: "Revolutionizing the movement of goods and services through innovative logistics infrastructure, transportation solutions, and supply chain optimization.",
      icon: "🚚",
      products: ["VTB Logistics"],
      // stats: { projects: 7, clients: "850+", satisfaction: "93%" }
    },
    {
      title: "Real Estate & Development",
      description: "Shaping the future of property through sustainable development, innovative housing solutions, and strategic commercial investments in prime markets.",
      icon: "🏙️",
      products: ["Jayco Estates"],
      // stats: { projects: 12, clients: "300+", satisfaction: "96%" }
    },
    {
      title: "Compliance & Verification",
      description: "Providing critical infrastructure for identity verification, regulatory compliance, and secure data handling through advanced API technologies.",
      icon: "🔐",
      products: ["Verification API Service"],
      // stats: { projects: 4, clients: "950+", satisfaction: "98%" }
    },
    {
      title: "Agriculture & Agri-Tech",
      description: "Leveraging technology to drive sustainable farming, improve food security, and enhance supply chain efficiency in the agricultural sector.",
      icon: "🌾",
      products: ["AgriSmart Solutions"],
      // stats: { projects: 9, clients: "1,800+", satisfaction: "94%" }
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }
  };

  const handleIndustryClick = (index) => {
    // This could navigate to industry detail pages in the future
    console.log(`Clicked on industry: ${industries[index].title}`);
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Animated background */}
      <div 
        className="absolute inset-0 z-0" 
        style={{
          backgroundImage: "url('/assets/jb2.jpg')",
          backgroundSize: "cover",
          backgroundPosition: `center ${scrollY * 0.5}px`,
          backgroundBlendMode: "overlay",
        }}
      />
      
      {/* Gradient overlay */}
      <div className="absolute inset-0 z-0 bg-gradient-to-b from-black via-black/90 to-black/80" />
      
      {/* Social Icons (Matching Home page) */}
      <div className="hidden md:flex fixed top-1/2 left-2 transform -translate-y-1/2 flex-col items-center space-y-8 z-50">
        {/* Vertical Line */}
        <div className="absolute top-5 left-20 h-full border-l-2 border-white"></div>

        {/* Social Icons */}
        <a
          href="https://www.facebook.com/share/1Yq4Sv7Fyo/?mibextid=wwXIfr"
          target="_blank"
          rel="noopener noreferrer"
          className="w-12 h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://x.com/Rendezvouscare"
          target="_blank"
          rel="noopener noreferrer"
          className="w-12 h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com/rendezvouscare?igsh=MWJ5c3A1ZWIycnh3NQ=="
          target="_blank"
          rel="noopener noreferrer"
          className="w-12 h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/company/jayco-links-nig-ltd/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-12 h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaLinkedinIn />
        </a>
      </div>
      
      {/* Content container */}
      <div className="relative z-10 px-4 py-16 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="text-center mb-20"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            className="inline-block mb-6"
          >
            <span className="inline-block px-4 py-1 rounded-full bg-[#056CB9] text-sm font-medium text-white">
              Industry Portfolio
            </span>
          </motion.div>
          
          <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-6 text-white">
            Investing Across Industries
          </h1>
          
          <motion.p 
            className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            At Jayco Links, we identify transformative opportunities across diverse sectors,
            building solutions that shape the future of business and lifestyle.
          </motion.p>
        </motion.div>

        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {industries.map((industry, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              onHoverStart={() => setHoveredIndex(index)}
              onHoverEnd={() => setHoveredIndex(null)}
              className="group relative h-full"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
            >
              <div className="absolute inset-0 rounded-xl bg-black/50 transform rotate-1 scale-[0.98] group-hover:rotate-2 group-hover:scale-[0.98] transition-all duration-300" />
              
              <div className="relative h-full rounded-xl overflow-hidden backdrop-blur-sm border border-white/10 group-hover:border-[#056CB9]/50 transition-all duration-500">
                {/* Subtle animated background */}
                <div className="absolute inset-0 bg-gradient-to-b from-black/80 to-black/40 opacity-100 group-hover:opacity-90 transition-opacity duration-500" />
                
                <div className="relative p-8 h-full flex flex-col">
                  {/* Industry icon with animated background */}
                  <div className="relative mb-6">
                    <motion.div
                      className="absolute inset-0 rounded-full bg-[#056CB9] opacity-30 blur-lg"
                      animate={{
                        scale: [1, 1.1, 1],
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        repeatType: "reverse"
                      }}
                    />
                    <div className="relative w-14 h-14 flex items-center justify-center rounded-full bg-black/60 border border-[#056CB9]/30">
                      <span className="text-2xl">{industry.icon}</span>
                    </div>
                  </div>
                  
                  {/* Industry title */}
                  <h2 className="text-2xl font-bold mb-3 text-white group-hover:text-white transition-colors duration-300">
                    {industry.title}
                  </h2>
                  
                  {/* Industry description */}
                  <p className="text-gray-300 group-hover:text-white/90 mb-6 flex-grow">
                    {industry.description}
                  </p>
                  
                  {/* Stats section */}
                  {/* <div className="flex justify-between items-center mt-auto pt-6 border-t border-white/10 text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-300">
                    <div className="flex space-x-5">
                      <div>
                        <span className="block text-lg font-bold text-white">{industry.stats.projects}</span>
                        <span>Projects</span>
                      </div>
                      <div>
                        <span className="block text-lg font-bold text-white">{industry.stats.clients}</span>
                        <span>Clients</span>
                      </div>
                    </div>
                    
                    <button 
                      onClick={() => handleIndustryClick(index)}
                      className="w-10 h-10 flex items-center justify-center rounded-full bg-[#056CB9]/20 group-hover:bg-[#056CB9] transition-all duration-300"
                      aria-label={`Learn more about ${industry.title}`}
                    >
                      <ChevronRight className="w-5 h-5 text-white transform group-hover:translate-x-0.5 transition-transform duration-300" />
                    </button>
                  </div> */}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
        
        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.6 }}
          className="mt-24 text-center"
        >
          <div className="inline-flex items-center mb-4 space-x-2">
            <Star className="w-5 h-5 text-[#056CB9]" />
            <Star className="w-5 h-5 text-[#056CB9]" />
            <Star className="w-5 h-5 text-[#056CB9]" />
            <Star className="w-5 h-5 text-[#056CB9]" />
            <Star className="w-5 h-5 text-[#056CB9]" />
          </div>
          <p className="text-2xl font-medium mb-8 text-gray-300">
            Ready to explore partnership opportunities?
          </p>
          <button 
            onClick={() => setCurrentPage('Contact')} 
            className="px-8 py-4 rounded-xl bg-[#056CB9] text-white text-lg font-semibold hover:bg-[#0a5ea0] transition-all duration-300 hover:shadow-lg"
          >
            Connect With Us
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default IndustryPortfolio;